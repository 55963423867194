@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    text-rendering: optimizeLegibility;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 15px;
    line-height: 1.5;
    color: #000000;
    font-weight: 400;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html{
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    scroll-behavior: smooth;
    line-height: 1.5;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6, p {
    line-height: 1.5;
    font-family: "Rubik", sans-serif;
    white-space: pre-line;
}

.text-new-line{
    white-space: pre-line;
}

button,
input,
optgroup,
select,
textarea {
    font-family: inherit;
    font-size: 100%;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    margin: 0;
    padding: 0;
}

.form-input {
    padding: 8px;
}


.font-rubik{
    font-family: "Rubik", sans-serif !important;
}

.font-semibold{
    font-weight: 600 !important;
}

.text-base{
    font-size: 1rem !important;
    line-height: 1.5rem !important;
}
.text-lg{
    font-size: 1.125rem !important;
    line-height: 1.75rem !important;
}

.relative{
    position: relative !important;
}

.container{
    width: 70%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 12px;
    padding-left: 12px;
    overflow-x: hidden;
}

.navbar{
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background-color: transparent;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    -webkit-transition-property: all;
    transition-property: all;
    -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    max-height: 70px;
    width: 100%;
    position: fixed;
}
.navbar .navigation{
    margin: 0px;
    width: 100%;
}

.navbar .navigation .navbar-nav{
    margin-top: 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 6rem;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    overflow-y: scroll;
}

.navbar .navbar-brand{
    -webkit-margin-end: 3rem;
    margin-inline-end: 3rem;
    padding: 0px;
}

.logo{
    max-height: 50px;
    width: auto;
}
.header-container{
    width: 70%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
}

.flex{
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
}

.flex-wrap{
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
}

.items-center{
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
}

.justify-end{
    -webkit-box-pack: end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
}

.nav-items {
    display: flex;
    list-style: none;
}

.nav-item {
    margin-right: 20px;
    cursor: pointer;
    /*color: white;*/
}

.navbar .navigation .navbar-nav .nav-link{
    display: block;
    /*padding: 0.75rem;*/
    --tw-text-opacity: 1;
    color: rgb(15 23 42 / var(--tw-text-opacity));
}
.navbar .navigation .navbar-nav .nav-link:hover{
    --tw-text-opacity: 1;
    color: rgb(234 88 12 / var(--tw-text-opacity));
}

.navbar .navigation .navbar-nav .active .nav-link{
    --tw-text-opacity: 1;
    color: rgb(234 88 12 / var(--tw-text-opacity));
}

.contact-form,
.info {
    flex: 1;
    padding: 20px;
    margin: 10px;
}

.contact-form {
    background: #f9f9f9;
    padding: 20px;
    border: 1px solid #ddd;
}

.contact-form label {
    display: block;
    margin-top: 10px;
}

.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form textarea {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
}

.contact-form input[type="submit"] {
    display: block;
    padding: 10px 15px;
    border: 0;
    cursor: pointer;
    width: 100%;
    margin-top: 10px;
}

.contact-form input[type="submit"]:hover {
    background: #555;
}

.contact-container {
    width: 80%;
    margin: auto;
    overflow: hidden;
    min-height: 100vh;
    padding-bottom: 50px;
    box-sizing: border-box;
}

.content {
    display: flex;
    justify-content: space-between;
}

.info {
    border: 1px solid #ddd;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.section {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 10vh;
    padding: 20px;
    width: 95%;
    margin: auto;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}

.overlay-dark {
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
}

.overlay-light {
    background-color: rgba(255, 255, 255, 0.5); /* Semi-transparent white */
}

.gradient-bg {
    position: relative;
    display: inline-block;
    padding: 0.5em 1em;
    background: inherit;
    z-index: 1;
}

.gradient-bg::before {
    content: '';
    position: absolute;
    top: 0;
    left: -4em;
    right: -5em;
    bottom: 0;
    background: inherit;
    z-index: -1;
    border-radius: inherit;
    pointer-events: none;
}

.gradient-bg-light::before {
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0));
}

.gradient-bg-dark::before {
    background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8) 50%, rgba(0, 0, 0, 0));
}

.text-shadow {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.content {
    display: flex;
    justify-content: center;
    align-items: stretch;
}

.content .image {
    flex: 1;
    min-width: 50%;
}

.image-first {
    flex-direction: row;
}

.text-first {
    flex-direction: row-reverse;
}

.image img {
    width: 100%;
    height: auto;
    border: 3px solid #666666;
}

@media (min-width: 768px) {
    .image img {
        width: 75%;
    }
}

@media (min-width: 1024px) {
    .image img {
        width: 50%;
    }
}

.section-light {
    background: #1e293b;
}

.section-dark {
    background: #0f172b;
}

.pageTitle{
    color: #002f46;
    font-size: 30px;
    font-weight: bold;
    margin-left: 5%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    padding: 5px;
}

.modal-background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-content {
    padding: 20px;
    border-radius: 5px;
    z-index: 1001;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px #0f172b inset !important;
    -webkit-text-fill-color: white !important;
}

.grid img {
    width: 100%;
    height: auto;
}
